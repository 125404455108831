import { For, createSignal, Show, JSXElement } from "solid-js";
import { Stack, Typography, IconButton, Popover, Grid, } from "@suid/material";
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { getText, GetTextUI } from '../SmallComponents/Translate'

import { CandidateCVIndustriesDataElementsListType } from "../../../types/candidateCVDataTypes"

import { FactoryIcon, TransportationIcon, LocalHospitalIcon, SatelliteIcon, LocalGroceryStoreIcon, BuildingMaterialsIcon,
    RetailIcon,MediaIcon,DirectionsCarIcon,ComputerIcon, AccountBalanceIcon, HomeIcon,
    AccountBalanceWalletIcon,ShieldIcon,BuildIcon,WorkIcon,FlashOnIcon,StoreIcon,SecurityIcon,
    SchoolIcon,FlightTakeoffIcon,SportsSoccerIcon,LocalGasStationIcon,CheckroomIcon,EngineeringIcon} from '../iconsindustries' 



interface categoriesType {
    Value: string;
    Icon: JSXElement;
}

interface IndustryElementProps {
    iconData: categoriesType;
}

const IndustryElement = ({ iconData }: IndustryElementProps) => {
    const [industryData, setIndustryData] = createSignal(iconData);
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = () => Boolean(anchorEl());
    const txtNoExperience = getText("industrycomponent", "noexperience")
    const txtExperience = getText("industrycomponent", "experience")

    return (
        <>
            <Typography
                aria-owns={open() ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <>
                    <IconButton color="success">
                        {industryData()?.Icon}
                    </IconButton>
                </>

            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none" }}
                open={open()}
                anchorEl={anchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >

                <>
                    <Typography sx={{ p: 1 }}>
                        {txtExperience()} <GetTextUI formName={"industrycomponent"} label={industryData()?.Value} />
                    </Typography>
                </>


            </Popover>

        </>
    )
};

/*
<For
each={categories}
fallback={<p>{txtLoading()}</p>}
>
{(item) => <IndustryElement iconData={item} />}
</For>
*/

const IndustryList = (props: CandidateCVIndustriesDataElementsListType) => {
    const txtLoading = getText("default", "loading")
    const txtHeader = getText("industrycomponent", "header")

    const categories: categoriesType[] = [
        { Value: "v01", Icon: <FactoryIcon /> },
        { Value: "v02", Icon: <RetailIcon /> },
        { Value: "v03", Icon: <BuildingMaterialsIcon /> },
        { Value: "v04", Icon: <LocalHospitalIcon /> },
        { Value: "v05", Icon: <MediaIcon /> },
        { Value: "v06", Icon: <DirectionsCarIcon /> },
        { Value: "v07", Icon: <ComputerIcon /> },
        { Value: "v08", Icon: <AccountBalanceIcon /> },
        { Value: "v09", Icon: <HomeIcon /> },
        { Value: "v10", Icon: <AccountBalanceWalletIcon /> },
        { Value: "v11", Icon: <ShieldIcon /> },
        { Value: "v12", Icon: <LocalGroceryStoreIcon /> },
        { Value: "v13", Icon: <BuildIcon /> },
        { Value: "v14", Icon: <WorkIcon /> },
        { Value: "v15", Icon: <FlashOnIcon /> },
        { Value: "v16", Icon: <StoreIcon /> },
        { Value: "v17", Icon: <TransportationIcon /> },
        { Value: "v18", Icon: <SecurityIcon /> },
        { Value: "v19", Icon: <SchoolIcon /> },
        { Value: "v20", Icon: <FlightTakeoffIcon /> },
        { Value: "v21", Icon: <SportsSoccerIcon /> },
        { Value: "v22", Icon: <LocalGasStationIcon /> },
        { Value: "v23", Icon: <CheckroomIcon /> },
        { Value: "v24", Icon: <MediaIcon /> },
        { Value: "v25", Icon: <EngineeringIcon /> },
    ];

    const findCategoryByValue = (inputValue: string | number): categoriesType | undefined =>{
        if (!inputValue) 
            return undefined;
        // Convert the input value to string if it's a number
        let value = typeof inputValue === 'number' ? inputValue.toString() : inputValue;

        // If the value is a number without 'v', prepend 'v' and pad it with zero if needed
        if (!isNaN(Number(value)) && !value.startsWith('v')) {
            value = 'v' + value.padStart(2, '0');
        }

        const element = categories.find(category => category.Value === value);
        if (!element) {
            return undefined;
        }
        else {
            return element;
        }
    }

    return (
        <>
        <Show when={!props.showButton}
            fallback={
                <IndustryElement iconData={findCategoryByValue(props?.singleButtonIndustryId as string) as categoriesType} />
            }
        >
            <Stack
                direction="column"
                sx={{ px: 2, py: 1, bgcolor: 'background.default'[50] }}
            >
                <ShowHeading pxValue={0} size="small">{txtHeader()}</ShowHeading>

                <Grid container justifyContent="flex-end" direction='row'  >
                    <Show when={props?.loadAll}
                        fallback={
                            <For
                                each={props.valueList}
                                fallback={<p>{txtLoading()}</p>}
                            >
                                {(item) =>
                                    <IndustryElement iconData={findCategoryByValue(item.industryId) as categoriesType} />
                                }
                            </For>
                        }

                    >
                        <For
                            each={categories}
                            fallback={<p>{txtLoading()}</p>}
                        >
                            {(item) =>
                                <IndustryElement iconData={item}  />
                            }
                        </For>
                    </Show>
                </Grid>
            </Stack>
            </Show>
        </>
    )
}

export { IndustryList }